import { AppInterface, SocialLinks } from "types/types"
import AppPreview from "components/App"
import ProgressSpinner from "components/Spinner"
import useT from "utils/useT"
import useSessionStore from "stores/session"
import useQueryPlus from "utils/useQueryPlus"
import imagePlaceholder from "utils/imagePlaceholder"
import useGlobalStore from "stores/global"
import { fetchProfile } from "queries"
import { FallbackImage } from "components/FallbackImage"
import Link from "next/link"
import useMediaQuery from "utils/useMediaQuery"
import ProfileSocialLinks from "./ProfileSocialLinks"
import removeHttp from "utils/removeHttp"
import RenderIfVisible from "react-render-if-visible"

interface Props {
  userId: string
  userData?: {
    photoURL?: string
    displayName?: string
    bio?: string
    email?: string
  }
  userApps?: AppInterface[]
}

const ProfileCard = ({ userId, userData, userApps }: Props) => {
  const t = useT()
  const isDesktop = useMediaQuery("(min-width: 900px)")
  const isDark = useGlobalStore((state) => state.isDark)
  const loggedUserId = useSessionStore((state) => state.user.uid)

  const sameUser = loggedUserId && userId.includes(loggedUserId)

  const { data: profileData, isLoading } = useQueryPlus({
    enabled: !!userId,
    queryKey: ["profile", userId],
    initialData: userData,
    queryFn: () => fetchProfile(userId),
  })
  const { data: apps, isLoading: loadingApps } = useQueryPlus<AppInterface[]>({
    queryKey: ["userApps", userId],
    initialData: userApps,
    queryFn: () => fetch(`/api/user?id=${userId}`).then((res) => res.json()),
  })

  const photoURL = profileData?.photoURL
  const displayName = profileData?.displayName
  const bio = profileData?.bio
  const email = profileData?.email
  const links = profileData?.links as SocialLinks

  return (
    <div>
      <div className="card card-style">
        <div className="m-3">
          <div className="d-flex mb-2">
            <div className="p-1 pt-0 pe-3">
              <FallbackImage
                key={photoURL}
                src={
                  isLoading
                    ? imagePlaceholder(isDark)
                    : photoURL
                    ? photoURL
                    : "/images/avatars/4s.png"
                }
                width={80}
                height={80}
                className="rounded-circle"
                alt="profile photo"
                placeholder="blur"
                blurDataURL={imagePlaceholder(isDark)}
                unoptimized
              />
            </div>

            <div className="d-flex justify-content-between flex-grow-1 flex-wrap">
              <div>
                <h1 className="font-700 font-20 pe-4 mb-0">
                  {displayName || <>&nbsp;</>}
                </h1>
                {!links && (
                  <p className="font-10 mb-0">
                    <strong className="color-theme pe-1">
                      {apps?.length ?? 0}
                    </strong>
                    {t.profile.appSubmissions}
                  </p>
                )}
              </div>
              <ProfileSocialLinks links={links} />
            </div>
          </div>
          <div className="flex-grow-1">
            <p dir="auto" className="font-13 text-break">
              {isLoading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : bio ? (
                bio
              ) : sameUser ? (
                t.profile.noDescription
              ) : null}
            </p>
            {sameUser && email && <div className="font-13">{email}</div>}
            {links && (
              <p className="font-10 mb-0">
                <strong className="color-theme pe-1">
                  {apps?.length ?? 0}
                </strong>
                {t.profile.appSubmissions}
              </p>
            )}
            {links?.OtherLink && (
              <a
                href={links?.OtherLink}
                className="font-700 font-12"
                target="_blank"
                rel="me nofollow noopener noreferrer"
              >
                <p className="text-truncate">{removeHttp(links?.OtherLink)}</p>
              </a>
            )}
          </div>
        </div>

        {sameUser && (
          <div className="content">
            <Link
              href="/edit-profile"
              className="btn btn-full btn-sm btn-border rounded-s text-uppercase font-900 color-highlight border-blue-dark"
              shallow
            >
              {t.profile.editProfile}
            </Link>
          </div>
        )}
        <div className="divider mb-3 mt-1"></div>
        {loadingApps ? (
          <div className="m-auto p-1 pb-4">
            <ProgressSpinner />
          </div>
        ) : apps?.length === 0 ? (
          <div className="text-center m-auto p-5">
            <div>{t.profile.noApps}</div>
            <Link href="/submit" className="btn bg-primary m-4">
              {t.profile.submitNow}
            </Link>
          </div>
        ) : (
          <div className="profile-apps-container">
            {apps?.map((app) => (
              <RenderIfVisible
                key={app.trackId}
                defaultHeight={104}
                visibleOffset={200}
              >
                <AppPreview {...app} size={70} ssr={isDesktop} />
              </RenderIfVisible>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileCard
