import Link from "next/link"
import Image from "next/image"
import { ReactElement } from "react"
import { AppInterface } from "../types/types"
import { motion } from "framer-motion"
import useGlobalStore from "../stores/global"
import useRouterStore from "stores/routerStore"
import imagePlaceholder from "utils/imagePlaceholder"
import getLink from "utils/getLink"

interface Props extends AppInterface {
  i?: number
  size?: number
  ssr?: boolean
}
export default function AppPreview({
  trackId,
  artworkUrl512,
  trackName,
  size = 100,
  i,
  ssr,
}: Props): ReactElement {
  const isDark = useGlobalStore((state) => state.isDark)
  const pathname = useRouterStore((state) => state.router?.pathname)
  const query = useRouterStore((state) => state.router?.query)

  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const enableAnimation = typeof i !== "undefined" && i > 3 && animationToggle
  const as =
    trackId && trackName !== "Loading..." ? getLink(trackId, trackName) : ""
  const href = trackId
    ? {
        pathname: pathname,
        query: {
          ...query,
          appId: trackId,
        },
      }
    : ""
  return (
    <motion.div
      className="app-item"
      initial={enableAnimation && { transform: "scale(1)", opacity: 0 }}
      animate={
        enableAnimation && {
          transform: ["scale(1)", "scale(0.80)", "scale(1)"],
          opacity: 1,
        }
      }
      transition={
        enableAnimation
          ? {
              duration: 0.2,
              type: "keyframes",
              ease: "easeOut",
            }
          : undefined
      }
    >
      <Link
        as={ssr ? undefined : as}
        href={ssr ? as : href}
        className="app-item"
        scroll={false}
      >
        <Image
          className="app-img w-100 h-100"
          width={size}
          height={size}
          style={{
            maxHeight: size,
            maxWidth: size,
          }}
          src={artworkUrl512 || imagePlaceholder(isDark)}
          placeholder="blur"
          blurDataURL={imagePlaceholder(isDark)}
          alt="app icon"
          priority={Boolean(i && i < 4)}
          unoptimized
        />

        <p className="app-name">{trackName || "Loading..."}</p>
      </Link>
    </motion.div>
  )
}
