import useQueryPlus from "utils/useQueryPlus"
import PagesLayout from "../layouts/PagesLayout"

import { fetchInitials } from "../queries"

const Home = () => {
  useQueryPlus({
    queryFn: fetchInitials,
    queryKey: ["initialApps"],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  return <PagesLayout />
}

export default Home
