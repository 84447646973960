import { motion } from "framer-motion"
import DragClose from "../components/DragClose"
import ProfileCard from "../views/profile/ProfileCard"
import useGlobalStore from "../stores/global"
import HomePages from "./HomePages"
import { memo } from "react"
import useRouterStore from "stores/routerStore"

const PagesLayout = () => {
  const userIdOriginal = useRouterStore((state) => state.router?.query?.userId)
  const back = useRouterStore((state) => state.router?.back)
  const userId = JSON.stringify(userIdOriginal)
  const animationToggle = useGlobalStore((state) => state.animationToggle)

  if (userId)
    return (
      <motion.div
        initial={animationToggle && { x: "70%", opacity: 0 }}
        animate={animationToggle && { x: [700, -25, 0], opacity: 1 }}
        exit={
          animationToggle
            ? {
                x: "60%",
                opacity: 0,
                transition: { duration: 0.15 },
              }
            : undefined
        }
        transition={
          animationToggle
            ? {
                duration: 0.3,
                type: "keyframes",
                ease: "easeOut",
              }
            : undefined
        }
      >
        <DragClose callbackFn={back}>
          <ProfileCard userId={userId} />
        </DragClose>
      </motion.div>
    )
  return <HomePages />
}

export default memo(PagesLayout)
