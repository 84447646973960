import React from "react"
import { SocialLinks } from "types/types"
import openShareSheet from "utils/openShareSheet"
import { FiShare } from "react-icons/fi"
type Props = {
  links: SocialLinks
}

const ProfileSocialLinks = ({ links }: Props) => {
  return (
    <div>
      <div className="d-flex gap-1 mb-n2">
        {links?.facebookLink && (
          <a
            href={links?.facebookLink}
            className="mb-4 icon icon-xxs bg-facebook rounded-sm font-700"
            target="_blank"
            rel="me nofollow noopener noreferrer"
          >
            <i className="fab fa-facebook-f font-14"></i>
          </a>
        )}
        {links?.twitterLink && (
          <a
            href={links?.twitterLink}
            className="mb-4 icon icon-xxs bg-twitter rounded-sm font-700"
            target="_blank"
            rel="me nofollow noopener noreferrer"
          >
            <i className="fab fa-twitter font-14"></i>
          </a>
        )}
        {links?.instaLink && (
          <a
            href={links?.instaLink}
            className="mb-4 icon icon-xxs bg-instagram rounded-sm font-700"
            target="_blank"
            rel="me nofollow noopener noreferrer"
          >
            <i className="fab fa-instagram font-14"></i>
          </a>
        )}
        <a
          onClick={openShareSheet}
          className="mb-4 icon icon-xxs rounded-sm shadow-l bg-red-dark"
          target="_blank"
          rel="noreferrer"
        >
          <FiShare className="font-14 p-2" size={31} strokeWidth={2.4} />
        </a>
      </div>
    </div>
  )
}

export default ProfileSocialLinks
