import { motion } from "framer-motion"
import dynamic from "next/dynamic"
import { memo } from "react"
import useGlobalStore from "stores/global"
import useRouterStore from "stores/routerStore"
import useMediaQuery from "utils/useMediaQuery"

const SearchPage = dynamic(() => import("../views/search"))
const HomePage = dynamic(() => import("../views/home"))
const FeedPage = dynamic(() => import("../views/feed"))
const SettingsPage = dynamic(() => import("../views/settings"))
const AppsPage = dynamic(() => import("../views/apps"))

const HomePages = () => {
  const pageTitle =
    useRouterStore((state) => state.router?.query?.page) || "home"
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const appId = useRouterStore((state) => state.router?.query?.appId)
  const isMobile = useMediaQuery("(max-width: 900px)")
  const showPages = !(appId && !isMobile)

  const pages = [
    {
      name: "apps",
      path: "/apps",
      component: <AppsPage />,
    },
    {
      name: "search",
      path: "/search",
      component: <SearchPage />,
    },
    {
      name: "home",
      path: "/",
      component: <HomePage />,
    },
    {
      name: "feed",
      path: "/feed",
      component: <FeedPage />,
    },
    {
      name: "settings",
      path: "/settings",
      component: <SettingsPage />,
    },
  ]
  if (showPages)
    return (
      <motion.div
        key="pages"
        initial={animationToggle && { opacity: 0 }}
        animate={animationToggle && { opacity: 1 }}
        exit={
          animationToggle
            ? {
                opacity: 0,
                transition: { duration: 0.15 },
              }
            : undefined
        }
        transition={
          animationToggle
            ? {
                duration: 0.3,
                type: "keyframes",
                ease: "easeOut",
              }
            : undefined
        }
      >
        {pages.map((page) => (
          <div key={page.name}>
            {page.name === pageTitle ? page.component : null}
          </div>
        ))}
      </motion.div>
    )
  return null
}

export default memo(HomePages)
